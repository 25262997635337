import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bp-datamanagement-view" }
const _hoisted_2 = { class: "flex-column gap-md" }
const _hoisted_3 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_4 = { class: "flex gap-md" }
const _hoisted_5 = {
  key: 0,
  style: {"opacity":"0.5"}
}
const _hoisted_6 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_7 = { class: "flex-column gap-md" }
const _hoisted_8 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_9 = {
  class: "flex gap-md",
  style: {"align-items":"center"}
}
const _hoisted_10 = { class: "flex-column gap-md pt-md" }
const _hoisted_11 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_12 = { class: "flex-column gap-md pt-md" }
const _hoisted_13 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_14 = { class: "flex-column gap-md pt-md" }
const _hoisted_15 = { class: "bp-datamanagement__qr-code" }
const _hoisted_16 = { class: "flex-column gap-md pt-md" }
const _hoisted_17 = { class: "flex-column gap-md pt-md" }
const _hoisted_18 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_19 = { class: "flex-column gap-md" }
const _hoisted_20 = { class: "mono" }
const _hoisted_21 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_22 = {
  key: 0,
  class: "flex-column gap-md"
}
const _hoisted_23 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_24 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_25 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_26 = { class: "flex-column gap-md" }
const _hoisted_27 = { class: "bp-datamanagement-view__button-wrapper" }
const _hoisted_28 = { class: "flex-column gap-md" }
const _hoisted_29 = { style: {"display":"grid","margin":"0 auto","grid-template-columns":"repeat(5, 1fr)","gap":"1rem 2rem","width":"fit-content"} }
const _hoisted_30 = { class: "flex-column gap-md" }
const _hoisted_31 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_password_validator = _resolveComponent("bp-password-validator")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_qrcode = _resolveComponent("bp-qrcode")!
  const _component_bp_customer_editor = _resolveComponent("bp-customer-editor")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-datamanagement-view__header",
      image: "/static/background/app_background_datamanagement.jpg",
      icon: ['fad', 'address-card']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[41] || (_cache[41] = [
            _createTextVNode("Datamanagement")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      tabs: _ctx.sessionUser.email !== 'admin' ? [
      { _id: 'account', name: _ctx.$gettext('Account details'), icon: ['far', 'circle-user'] },
      { _id: 'security', name: _ctx.$gettext('Security'), icon: ['far', 'lock'] },
      { _id: 'customer', name: _ctx.$gettext('Customer settings'), icon: ['far', 'users'] },
      { _id: 'email_notifications', name: _ctx.$gettext('Email notifications'), icon: ['far', 'envelope'] },
      { _id: 'delete_account', name: _ctx.$gettext('Delete account'), icon: ['far', 'trash-can'] }
    ] : [
      { _id: 'security', name: _ctx.$gettext('Security'), icon: ['far', 'lock'] }
    ],
      "tabs-position": "inside",
      "fill-height": "",
      "fill-width": ""
    }, {
      "tab-content-account": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[42] || (_cache[42] = [
              _createTextVNode("Email address")
            ])),
            _: 1
          }),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Current email address'),
            error: (_ctx.changeEmailData.currentEmail.dirty && _ctx.changeEmailData.currentEmail.value !== _ctx.user.email) ? _ctx.$gettext('Does not match your current email address') : undefined,
            placeholder: _ctx.user.email?.replace(/^([\w\d]).*?([\w\d])@([\w\d]).*?([\w\d])$/, '$1***$2@$3***$4'),
            onChangeDirty: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeEmailData.currentEmail.dirty = $event)),
            onChangeValid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setChangeEmailError('current', _ctx.$gettext('Current email address'), $event))),
            required: "",
            type: "email",
            modelValue: _ctx.changeEmailData.currentEmail.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.changeEmailData.currentEmail.value) = $event))
          }, null, 8, ["label", "error", "placeholder", "modelValue"]),
          _createVNode(_component_bp_input, {
            error: (_ctx.changeEmailData.email.dirty && _ctx.changeEmailData.email.error) ? _ctx.changeEmailData.email.error : undefined,
            label: _ctx.$gettext('New email address'),
            placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
            onChangeDirty: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeEmailData.email.dirty = $event)),
            onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setChangeEmailError('new', _ctx.$gettext('New email address'), $event))),
            required: "",
            type: "email",
            modelValue: _ctx.changeEmailData.email.value,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.changeEmailData.email.value) = $event))
          }, null, 8, ["error", "label", "placeholder", "modelValue"]),
          _createVNode(_component_bp_input, {
            error: (_ctx.changeEmailData.confirmation.dirty && _ctx.changeEmailData.email.value !== _ctx.changeEmailData.confirmation.value) ? _ctx.$gettext('The email addresses do not match') : undefined,
            disabled: !!_ctx.changeEmailData.email.error,
            label: _ctx.$gettext('Confirm email address'),
            placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
            onChangeDirty: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeEmailData.confirmation.dirty = $event)),
            onChangeValid: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setChangeEmailError('confirm', _ctx.$gettext('Confirm email address'), $event))),
            required: "",
            type: "email",
            modelValue: _ctx.changeEmailData.confirmation.value,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.changeEmailData.confirmation.value) = $event))
          }, null, 8, ["error", "disabled", "label", "placeholder", "modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_bp_form_button, {
              color: "green",
              icon: ['far', 'envelope'],
              errors: _ctx.changeEmailErrors,
              action: _ctx.changeEmail
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[43] || (_cache[43] = [
                    _createTextVNode("Send confirmation email")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["errors", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "reset",
              action: _ctx.changeEmailReset
            }, null, 8, ["action"])
          ]),
          _cache[45] || (_cache[45] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[44] || (_cache[44] = [
              _createTextVNode("Contact information")
            ])),
            _: 1
          }),
          _createVNode(_component_bp_select, {
            data: _ctx.salutationStore.salutations,
            label: _ctx.$gettext('Salutation'),
            clearable: "",
            "search-property": "name",
            modelValue: _ctx.user.salutation,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.salutation) = $event))
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("div", _hoisted_4, [
                (option.icon)
                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                      key: 0,
                      icon: option.icon
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(option.name) + " ", 1),
                  (!option._disabled)
                    ? (_openBlock(), _createElementBlock("em", _hoisted_5, "(" + _toDisplayString(option.example) + ")", 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }, 8, ["data", "label", "modelValue"]),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Forename'),
            modelValue: _ctx.user.forename,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.forename) = $event)),
            maxlength: 100
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Surname'),
            modelValue: _ctx.user.surname,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.surname) = $event)),
            maxlength: 100
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Phone'),
            modelValue: _ctx.user.phone,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.phone) = $event)),
            type: "tel"
          }, null, 8, ["label", "modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_bp_form_button, {
              type: "save",
              action: _ctx.saveContactInformation
            }, null, 8, ["action"]),
            _createVNode(_component_bp_form_button, {
              type: "reset",
              action: _ctx.resetContactInformation
            }, null, 8, ["action"])
          ])
        ])
      ]),
      "tab-content-security": _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[46] || (_cache[46] = [
              _createTextVNode("Password")
            ])),
            _: 1
          }),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Current password'),
            placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
            onChangeValid: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setChangePasswordError('current', _ctx.$gettext('Current password'), $event))),
            autocomplete: "new-password",
            required: "",
            type: "password",
            modelValue: _ctx.changePasswordData.current.value,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.changePasswordData.current.value) = $event))
          }, null, 8, ["label", "placeholder", "modelValue"]),
          _createVNode(_component_bp_input, {
            error: (_ctx.changePasswordData.password.dirty && _ctx.changePasswordData.password.error) ? _ctx.changePasswordData.password.error : undefined,
            label: _ctx.$gettext('New password'),
            placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
            valid: !_ctx.changePasswordData.password.error,
            onChangeDirty: _cache[16] || (_cache[16] = ($event: any) => (_ctx.changePasswordData.password.dirty = $event)),
            onChangeValid: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setChangePasswordError('new', _ctx.$gettext('New password'), $event))),
            autocomplete: "new-password",
            required: "",
            type: "password",
            modelValue: _ctx.changePasswordData.password.value,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.changePasswordData.password.value) = $event))
          }, {
            text: _withCtx(() => [
              _createVNode(_component_bp_password_validator, {
                password: _ctx.changePasswordData.password.value,
                onChangeValid: _cache[15] || (_cache[15] = ($event: any) => (_ctx.changePasswordData.password.error = $event))
              }, null, 8, ["password"])
            ]),
            _: 1
          }, 8, ["error", "label", "placeholder", "valid", "modelValue"]),
          _createVNode(_component_bp_input, {
            error: (_ctx.changePasswordData.confirmation.dirty && _ctx.changePasswordData.password.value !== _ctx.changePasswordData.confirmation.value) ? _ctx.$gettext('The passwords do not match') : undefined,
            disabled: !!_ctx.changePasswordData.password.error,
            label: _ctx.$gettext('Confirm password'),
            placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
            valid: _ctx.changePasswordData.password.value === _ctx.changePasswordData.confirmation.value,
            onChangeDirty: _cache[19] || (_cache[19] = ($event: any) => (_ctx.changePasswordData.confirmation.dirty = $event)),
            onChangeValid: _cache[20] || (_cache[20] = ($event: any) => (_ctx.setChangePasswordError('confirm', _ctx.$gettext('Confirm password'), $event))),
            autocomplete: "new-password",
            required: "",
            type: "password",
            modelValue: _ctx.changePasswordData.confirmation.value,
            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.changePasswordData.confirmation.value) = $event))
          }, null, 8, ["error", "disabled", "label", "placeholder", "valid", "modelValue"]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_bp_form_button, {
              type: "save",
              errors: _ctx.changePasswordErrors,
              action: _ctx.changePassword
            }, null, 8, ["errors", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "reset",
              action: _ctx.changePasswordReset
            }, null, 8, ["action"])
          ]),
          (_ctx.sessionUser.email !== 'admin')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[69] || (_cache[69] = _createElementVNode("hr", null, null, -1)),
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[47] || (_cache[47] = [
                      _createTextVNode("Two-factor authentication")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_bp_input, {
                    type: "toggle",
                    readonly: "",
                    "model-value": _ctx.user.authenticator?.active
                  }, null, 8, ["model-value"])
                ]),
                (_ctx.user.authenticator?.active)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_translate, { tag: "h3" }, {
                        default: _withCtx(() => _cache[48] || (_cache[48] = [
                          _createTextVNode("Backup codes")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_bp_label, {
                        label: _ctx.$gettext('Backup codes have been generated')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_translate, {
                              tag: "p",
                              "translate-params": { count: 10 - _ctx.user.authenticator.backupCodes }
                            }, {
                              default: _withCtx(() => _cache[49] || (_cache[49] = [
                                _createTextVNode("%{count}/10 codes have been used.")
                              ])),
                              _: 1
                            }, 8, ["translate-params"]),
                            _createVNode(_component_translate, { tag: "p" }, {
                              default: _withCtx(() => _cache[50] || (_cache[50] = [
                                _createTextVNode("If you generate new backup codes, you automatically invalidate the old codes!")
                              ])),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_bp_form_button, {
                          color: "green",
                          icon: ['far', 'key'],
                          action: _ctx.generateBackupCodes
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[51] || (_cache[51] = [
                                _createTextVNode("Generate new backup codes")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["action"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.user.authenticator?.active)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_translate, { tag: "h3" }, {
                        default: _withCtx(() => _cache[52] || (_cache[52] = [
                          _createTextVNode("Deactivate time-based one-time password")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_bp_label, {
                        label: _ctx.$gettext('Enter the generated six-digit code')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[53] || (_cache[53] = [
                                _createTextVNode("This code should be displayed in your application or on your device.")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_bp_input, {
                              onChangeValid: _cache[22] || (_cache[22] = ($event: any) => (_ctx.setDeactivateAuthenticatorError('otp', _ctx.$gettext('Enter the generated six-digit code'), $event))),
                              chunks: 6,
                              required: "",
                              modelValue: _ctx.deactivateAuthenticatorData.otp,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.deactivateAuthenticatorData.otp) = $event))
                            }, null, 8, ["modelValue"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_bp_form_button, {
                          color: "red",
                          icon: ['far', 'lock-open'],
                          errors: _ctx.deactivateAuthenticatorErrors,
                          action: _ctx.deactivateAuthenticator
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[54] || (_cache[54] = [
                                _createTextVNode("Deactivate two-factor authentication")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["errors", "action"]),
                        _createVNode(_component_bp_form_button, {
                          type: "reset",
                          action: () => _ctx.deactivateAuthenticatorData.otp = ''
                        }, null, 8, ["action"])
                      ])
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createVNode(_component_translate, { tag: "h3" }, {
                        default: _withCtx(() => _cache[55] || (_cache[55] = [
                          _createTextVNode("Activate time-based one-time password")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_bp_label, null, {
                        label: _withCtx(() => [
                          _createVNode(_component_translate, {
                            tag: "h4",
                            "translate-params": { step: '1a' },
                            style: {"display":"inline"}
                          }, {
                            default: _withCtx(() => _cache[56] || (_cache[56] = [
                              _createTextVNode("Step %{step}:")
                            ])),
                            _: 1
                          }),
                          _cache[58] || (_cache[58] = _createTextVNode(" ")),
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[57] || (_cache[57] = [
                              _createTextVNode("Scan the displayed QR code")
                            ])),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[59] || (_cache[59] = [
                                _createTextVNode("Scan this code with your OTP application.")
                              ])),
                              _: 1
                            }),
                            _createElementVNode("span", _hoisted_15, [
                              _createVNode(_component_bp_qrcode, {
                                value: _ctx.qrUri,
                                options: { errorCorrectionLevel: 'L' }
                              }, null, 8, ["value"])
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_bp_label, null, {
                        label: _withCtx(() => [
                          _createVNode(_component_translate, {
                            tag: "h4",
                            "translate-params": { step: '1b' },
                            style: {"display":"inline"}
                          }, {
                            default: _withCtx(() => _cache[60] || (_cache[60] = [
                              _createTextVNode("Step %{step}:")
                            ])),
                            _: 1
                          }),
                          _cache[62] || (_cache[62] = _createTextVNode(" ")),
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[61] || (_cache[61] = [
                              _createTextVNode("Copy the shared secret")
                            ])),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[63] || (_cache[63] = [
                                _createTextVNode("Alternatively you can also enter the shared secret manually in your OTP application or device:")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_bp_input, {
                              readonly: "",
                              "model-value": _ctx.user.authenticator?.secret
                            }, {
                              suffix: _withCtx(() => [
                                _withDirectives(_createVNode(_component_bp_icon, {
                                  class: "cursor-pointer p-md",
                                  icon: ['far', 'copy'],
                                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.copyToClipboard(_ctx.user.authenticator?.secret || '')))
                                }, null, 512), [
                                  [_directive_tooltip, _ctx.$gettext('Copy to clipboard')]
                                ])
                              ]),
                              _: 1
                            }, 8, ["model-value"])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_bp_label, null, {
                        label: _withCtx(() => [
                          _createVNode(_component_translate, {
                            tag: "h4",
                            "translate-params": { step: '2' },
                            style: {"display":"inline"}
                          }, {
                            default: _withCtx(() => _cache[64] || (_cache[64] = [
                              _createTextVNode("Step %{step}:")
                            ])),
                            _: 1
                          }),
                          _cache[66] || (_cache[66] = _createTextVNode(" ")),
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[65] || (_cache[65] = [
                              _createTextVNode("Enter the generated six-digit code")
                            ])),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[67] || (_cache[67] = [
                                _createTextVNode("This code should be displayed in your application or on your device.")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_bp_input, {
                              onChangeValid: _cache[25] || (_cache[25] = ($event: any) => (_ctx.setActivateAuthenticatorError('otp', _ctx.$gettext('Enter the generated six-digit code'), $event))),
                              chunks: 6,
                              required: "",
                              modelValue: _ctx.activateAuthenticatorData.otp,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.activateAuthenticatorData.otp) = $event))
                            }, null, 8, ["modelValue"])
                          ])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_bp_form_button, {
                          color: "green",
                          icon: ['far', 'lock'],
                          errors: _ctx.activateAuthenticatorErrors,
                          action: _ctx.activateAuthenticator
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[68] || (_cache[68] = [
                                _createTextVNode("Activate two-factor authentication")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["errors", "action"]),
                        _createVNode(_component_bp_form_button, {
                          type: "reset",
                          action: () => _ctx.activateAuthenticatorData.otp = ''
                        }, null, 8, ["action"])
                      ])
                    ], 64))
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      "tab-content-customer": _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[70] || (_cache[70] = [
              _createTextVNode("General information")
            ])),
            _: 1
          }),
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('Customer number'),
            seamless: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.customer.customerNumber), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _cache[71] || (_cache[71] = _createElementVNode("hr", null, null, -1)),
          (_ctx.customer)
            ? (_openBlock(), _createBlock(_component_bp_customer_editor, {
                key: 0,
                modelValue: _ctx.customer,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.customer) = $event)),
                unwrap: "",
                included: ['street1', 'street2', 'street3', 'zip_code', 'city', 'country', 'products']
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_bp_form_button, {
              tooltip: _ctx.sessionUser.roles.includes('impersonate_user') ? _ctx.$gettext('Not available as impersonated user') : undefined,
              disabled: _ctx.sessionUser.roles.includes('impersonate_user'),
              type: "save",
              action: _ctx.saveCustomerInformation
            }, null, 8, ["tooltip", "disabled", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "reset",
              action: _ctx.resetCustomerInformation
            }, null, 8, ["action"])
          ])
        ])
      ]),
      "tab-content-email_notifications": _withCtx(() => [
        (_ctx.user.email_notifications)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              _createVNode(_component_translate, { tag: "h2" }, {
                default: _withCtx(() => _cache[72] || (_cache[72] = [
                  _createTextVNode("Email Notifications")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "h3" }, {
                default: _withCtx(() => _cache[73] || (_cache[73] = [
                  _createTextVNode("Subscribe for Software Update Notifications")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "p" }, {
                default: _withCtx(() => _cache[74] || (_cache[74] = [
                  _createTextVNode("Stay informed about the latest updates! Fill out the form below to receive email notifications whenever a new version of our software is released.")
                ])),
                _: 1
              }),
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('Software of Interest')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_23, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionStore.getRootProducts(), (product) => {
                      return (_openBlock(), _createBlock(_component_bp_input, {
                        key: product.uuid,
                        type: "checkbox",
                        flush: "",
                        text: _ctx.versionStore.getName(product),
                        "model-value": _ctx.user.email_notifications.download.products.includes(product.uuid),
                        "onUpdate:modelValue": ($event: any) => (_ctx.updateEmailNotificationProducts('download', product.uuid))
                      }, null, 8, ["text", "model-value", "onUpdate:modelValue"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_bp_select, {
                data: _ctx.languageStore.languages,
                label: _ctx.$gettext('Language'),
                "close-on-click": "",
                transform: (language) => _ctx.languageStore.getNameOf(language._id),
                modelValue: _ctx.user.email_notifications.download.language,
                "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.user.email_notifications.download.language) = $event)),
                text: _ctx.$gettext('Select your preferred language for email notifications.')
              }, null, 8, ["data", "label", "transform", "modelValue", "text"]),
              _createVNode(_component_bp_input, {
                flush: "",
                type: "checkbox",
                modelValue: _ctx.user.email_notifications.download.active,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.user.email_notifications.download.active) = $event)),
                label: _ctx.$gettext('Subscribe Now'),
                text: _ctx.$gettext('Yes, I want to subscribe and receive email notifications.')
              }, null, 8, ["modelValue", "label", "text"]),
              _createVNode(_component_translate, { tag: "p" }, {
                default: _withCtx(() => _cache[75] || (_cache[75] = [
                  _createTextVNode("By subscribing, you agree to receive email notifications about updates of our software. You can unsubscribe at any time.")
                ])),
                _: 1
              }),
              _cache[79] || (_cache[79] = _createElementVNode("hr", null, null, -1)),
              _createVNode(_component_translate, { tag: "h3" }, {
                default: _withCtx(() => _cache[76] || (_cache[76] = [
                  _createTextVNode("Subscribe for video updates")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "p" }, {
                default: _withCtx(() => _cache[77] || (_cache[77] = [
                  _createTextVNode("Never miss out on the latest insights! Fill out the form below to receive email notifications whenever a new video about our software products is released.")
                ])),
                _: 1
              }),
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('Software of Interest')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionStore.getRootProducts(), (product) => {
                      return (_openBlock(), _createBlock(_component_bp_input, {
                        key: product.uuid,
                        type: "checkbox",
                        flush: "",
                        text: _ctx.versionStore.getName(product),
                        "model-value": _ctx.user.email_notifications.video.products.includes(product.uuid),
                        "onUpdate:modelValue": ($event: any) => (_ctx.updateEmailNotificationProducts('video', product.uuid))
                      }, null, 8, ["text", "model-value", "onUpdate:modelValue"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_bp_select, {
                data: _ctx.languageStore.languages,
                label: _ctx.$gettext('Language'),
                "close-on-click": "",
                transform: (language) => _ctx.languageStore.getNameOf(language._id),
                modelValue: _ctx.user.email_notifications.video.language,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.user.email_notifications.video.language) = $event)),
                text: _ctx.$gettext('Select your preferred language for email notifications.')
              }, null, 8, ["data", "label", "transform", "modelValue", "text"]),
              _createVNode(_component_bp_input, {
                flush: "",
                type: "checkbox",
                modelValue: _ctx.user.email_notifications.video.active,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.user.email_notifications.video.active) = $event)),
                label: _ctx.$gettext('Subscribe Now'),
                text: _ctx.$gettext('Yes, I want to subscribe and receive email notifications.')
              }, null, 8, ["modelValue", "label", "text"]),
              _createVNode(_component_translate, { tag: "p" }, {
                default: _withCtx(() => _cache[78] || (_cache[78] = [
                  _createTextVNode("By subscribing, you agree to receive email notifications about new videos related to our software products. You can unsubscribe at any time.")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_bp_form_button, {
                  type: "save",
                  action: _ctx.saveEmailNotifications,
                  disabled: _ctx.userStore.isLoading()
                }, null, 8, ["action", "disabled"]),
                _createVNode(_component_bp_form_button, {
                  type: "reset",
                  action: _ctx.resetEmailNotifications
                }, null, 8, ["action"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      "tab-content-delete_account": _withCtx(() => [
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[80] || (_cache[80] = [
              _createTextVNode("Account deletion")
            ])),
            _: 1
          }),
          (_ctx.user.authenticator?.active)
            ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                default: _withCtx(() => _cache[81] || (_cache[81] = [
                  _createTextVNode("Please enter your current password and a valid otp code / backup code to confirm your account deletion!")
                ])),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                default: _withCtx(() => _cache[82] || (_cache[82] = [
                  _createTextVNode("Please enter your current password to confirm your account deletion!")
                ])),
                _: 1
              })),
          _createElementVNode("form", null, [
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Password'),
              onChangeValid: _cache[32] || (_cache[32] = ($event: any) => (_ctx.setDeleteAccountError('password', _ctx.$gettext('Password'), $event))),
              required: "",
              type: "password",
              modelValue: _ctx.deleteAccountData.password,
              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.deleteAccountData.password) = $event))
            }, null, 8, ["label", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_bp_form_button, {
              color: "red",
              icon: "trash-can",
              errors: _ctx.deleteAccountErrors,
              action: () => _ctx.deleteAccountData.window = true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[83] || (_cache[83] = [
                    _createTextVNode("Delete user account")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["errors", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "reset",
              action: _ctx.resetDeleteAccount
            }, null, 8, ["action"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["tabs"]),
    _createVNode(_component_bp_window, {
      modelValue: _ctx.backupCodesWindow,
      "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.backupCodesWindow) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[84] || (_cache[84] = [
            _createTextVNode("Backup codes")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: ['far', 'copy'],
          onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.copyToClipboard(_ctx.backupCodes.join('\n\n'))))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[86] || (_cache[86] = [
                _createTextVNode("Copy to clipboard")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_form_button, {
          color: "red",
          icon: "xmark",
          action: () => _ctx.backupCodesWindow = false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[87] || (_cache[87] = [
                _createTextVNode("Close")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_28, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[85] || (_cache[85] = [
              _createTextVNode("In the following you can find your backup codes:")
            ])),
            _: 1
          }),
          _createElementVNode("span", _hoisted_29, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.backupCodes, (backupCode) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "mono",
                key: backupCode
              }, _toDisplayString(backupCode), 1))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.deleteAccountData.window,
      "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.deleteAccountData.window) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[88] || (_cache[88] = [
            _createTextVNode("Delete account permanently?")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          color: "yellow",
          icon: ['far', 'trash-can'],
          onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.user.authenticator?.active ? _ctx.deleteAccountShowOTPWindow() : _ctx.deleteAccount()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[96] || (_cache[96] = [
                _createTextVNode("Delete account permanently")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: () => _ctx.deleteAccountData.window = false
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_30, [
          _createVNode(_component_translate, { tag: "p" }, {
            default: _withCtx(() => _cache[89] || (_cache[89] = [
              _createTextVNode("Are you sure you want to permanently delete your Cordaware customer portal user account?")
            ])),
            _: 1
          }),
          _createVNode(_component_translate, { tag: "p" }, {
            default: _withCtx(() => _cache[90] || (_cache[90] = [
              _createTextVNode("If you delete your account permanently you will lose access to:")
            ])),
            _: 1
          }),
          _createElementVNode("ul", null, [
            _createVNode(_component_translate, { tag: "li" }, {
              default: _withCtx(() => _cache[91] || (_cache[91] = [
                _createTextVNode("Downloads")
              ])),
              _: 1
            }),
            _createVNode(_component_translate, { tag: "li" }, {
              default: _withCtx(() => _cache[92] || (_cache[92] = [
                _createTextVNode("Videos")
              ])),
              _: 1
            }),
            _createVNode(_component_translate, { tag: "li" }, {
              default: _withCtx(() => _cache[93] || (_cache[93] = [
                _createTextVNode("etc.")
              ])),
              _: 1
            }),
            _createVNode(_component_translate, { tag: "li" }, {
              default: _withCtx(() => _cache[94] || (_cache[94] = [
                _createTextVNode("…")
              ])),
              _: 1
            })
          ]),
          _createVNode(_component_translate, { tag: "strong" }, {
            default: _withCtx(() => _cache[95] || (_cache[95] = [
              _createTextVNode("This action is irreversible!")
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.deleteAccountData.otp.window,
      "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.deleteAccountData.otp.window) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[97] || (_cache[97] = [
            _createTextVNode("Two-factor authentication")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          color: "yellow",
          icon: "check",
          action: _ctx.deleteAccount
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[99] || (_cache[99] = [
                _createTextVNode("Delete account permanently")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"]),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          action: () => _ctx.deleteAccountData.otp.window = false
        }, null, 8, ["action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[39] || (_cache[39] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteAccount && _ctx.deleteAccount(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_31, [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[98] || (_cache[98] = [
                _createTextVNode("This code should be displayed in your application or on your device.")
              ])),
              _: 1
            }),
            _createVNode(_component_bp_label, {
              label: _ctx.$gettext('Please enter your OTP Code'),
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_bp_input, {
                  chunks: 6,
                  ref: _ctx.deleteAccountRef,
                  type: "text",
                  modelValue: _ctx.deleteAccountData.otp.value,
                  "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.deleteAccountData.otp.value) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}