
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, nextTick, ref, VNodeRef, watch } from 'vue';
import { copyToClipboard } from '@/utils/navigator';
import { Customer, useCustomerStore } from '@/stores/customer';
import { json } from '@sahnee/ajax';
import { slashIcon } from '@/components/icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { User, useUserStore } from '@/stores/user';
import { useSalutationStore } from '@/stores/salutation';
import { useUser } from '@/utils/user';
import BpCustomerEditorVue from '@/components/customer/BpCustomerEditor.vue';
import BpInputVue from '@/components/BpInput.vue';
import BpToast from '@/components/toast/BpToasts';
import BpViewHeader from '@/components/BpViewHeader.vue';
import clone from '@sahnee/clone';
import router from '@/router';
import { useLanguageStore } from '@/stores/language';
import { Product, useVersionStore } from '@/stores/version';

export default defineComponent({
  name: 'Datamanagement',
  components: {
    BpViewHeader,
    BpCustomerEditor: BpCustomerEditorVue,
  },
  // TODO: Maybe split logic into seperate components, e.g. change email, change password, two-factor authentication, etc.
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const userStore = useUserStore();
    const customerStore = useCustomerStore();
    const salutationStore = useSalutationStore();
    const languageStore = useLanguageStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// USER & CUSTOMER
    ///-------------------------------------------------------------------

    const sessionUser = useUser();
    const user = ref<Partial<User>>({});
    const customer = ref<Partial<Customer>>({});

    watch(() => [userStore.loaded, customerStore.loaded, sessionUser.value.email], async () => {
      if (!sessionUser.value.email || (sessionUser.value.email === 'admin' && !sessionUser.value.roles.includes('impersonate_user') && (!userStore.loaded || !customerStore.loaded))) { return }
      const response = await userStore.readByEmail(sessionUser.value.email);
      if (!response || !response.success || !response.data) { return }
      user.value = clone(response.data);
      const responseCustomer = await customerStore.readById(user.value.company || '');
      if (!responseCustomer || !responseCustomer.success || !responseCustomer.data) { return }
      customer.value = clone(responseCustomer.data);
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// FORM ERROR HANDLING
    ///-------------------------------------------------------------------

    const { errors: activateAuthenticatorErrors, setError: setActivateAuthenticatorError } = useFormErrors();
    const { errors: changeEmailErrors, setError: setChangeEmailError } = useFormErrors();
    const { errors: changePasswordErrors, setError: setChangePasswordError } = useFormErrors();
    const { errors: deactivateAuthenticatorErrors, setError: setDeactivateAuthenticatorError } = useFormErrors();
    const { errors: deleteAccountErrors, setError: setDeleteAccountError } = useFormErrors();

    ///-------------------------------------------------------------------
    /// CHANGE EMAIL
    ///-------------------------------------------------------------------

    const changeEmailData = ref<{
      confirmation: { value: string, dirty: boolean, error: string },
      currentEmail: { value: string, dirty: boolean, error: string },
      email: { value: string, dirty: boolean, error: string },
    }>({
      confirmation: {
        value: '',
        dirty: false,
        error: '',
      },
      currentEmail: {
        value: '',
        dirty: false,
        error: '',
      },
      email: {
        value: '',
        dirty: false,
        error: '',
      },
    });

    function changeEmailReset() {
      changeEmailData.value = {
        ...changeEmailData.value,
        confirmation: {
          value: '',
          dirty: false,
          error: '',
        },
        currentEmail: {
          value: '',
          dirty: false,
          error: '',
        },
        email: {
          value: '',
          dirty: false,
          error: '',
        },
      };
    }

    async function changeEmail() {
      const response = await json<{ success: boolean; error: string; }>('/api/user-data', {
        method: 'POST',
        json: {
          action: "change-email",
          oldEmail: user.value.email,
          newEmail: changeEmailData.value.email.value,
        },
      });

      if (response.success) {
        router.push({ name: 'login' });
        BpToast.show({
          color: 'green',
          title: $gettext('Authenticate success'),
          content: $gettext('Authenticate success. Email was sent.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Authenticate failure'),
          content: response.error,
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// CHANGE PASSWORD
    ///-------------------------------------------------------------------

    const changePasswordData = ref<{
      confirmation: { value: string, dirty: boolean, error: string },
      current: { value: string, dirty: boolean, error: string },
      password: { value: string, dirty: boolean, error: string },
    }>({
      confirmation: {
        value: '',
        dirty: false,
        error: '',
      },
      current: {
        value: '',
        dirty: false,
        error: '',
      },
      password: {
        value: '',
        dirty: false,
        error: '',
      },
    });

    function changePasswordReset() {
      changePasswordData.value = {
        ...changePasswordData.value,
        confirmation: {
          value: '',
          dirty: false,
          error: '',
        },
        current: {
          value: '',
          dirty: false,
          error: '',
        },
        password: {
          value: '',
          dirty: false,
          error: '',
        },
      };
    }

    async function changePassword() {
      let response
      if (sessionUser.value.email === 'admin') {
        response = await json<{ success: boolean; error: string; }>('/api/local-admin', {
          method: 'POST',
          json: {
            action: "change-password",
            currentPassword: changePasswordData.value.current.value,
            newPassword: changePasswordData.value.password.value,
          },
        });
      } else {
        response = await json<{ success: boolean; error: string; }>('/api/store/users', {
          method: 'POST',
          json: {
            action: "change-password",
            email: user.value.email,
            oldPassword: changePasswordData.value.current.value,
            newPassword: changePasswordData.value.password.value,
          },
        });
      }

      if (response.success) {
        router.push({ name: 'login' });
        BpToast.show({
          color: 'green',
          title: $gettext('Password changed successfully'),
          content: $gettext('Your password was successfully changed. You will be logged out now.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failure'),
          content: response.error,
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// TWO-FACTOR AUTHENTICATOR
    ///-------------------------------------------------------------------

    const qrUri = computed(() => {
      const name = encodeURIComponent(`${$gettext('Cordaware customer portal')} (${sessionUser.value.email})`);
      return `otpauth://totp/${name}?secret=${user.value.authenticator?.secret || ''}`;
    });

    const activateAuthenticatorData = ref<{ otp: string }>({
      otp: '',
    });

    async function activateAuthenticator() {
      const response = await userStore.updateAuthenticator(true, sessionUser.value.email, activateAuthenticatorData.value.otp);
      if (response && response.success && response.data) {
        user.value = clone(response.data);
        backupCodes.value = response.backupCodes;
        backupCodesWindow.value = true;
        activateAuthenticatorData.value.otp = '';
        BpToast.show({
          color: 'green',
          title: $gettext('Authenticator confirm success'),
          content: $gettext('Authenticator confirmation successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Authenticator failed'),
          content: $gettext('Authenticator confirmation failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    const deactivateAuthenticatorData = ref<{ otp: string }>({
      otp: '',
    })

    async function deactivateAuthenticator() {
      const response = await userStore.updateAuthenticator(false, sessionUser.value.email, deactivateAuthenticatorData.value.otp);
      if (response && response.success && response.data) {
        user.value = clone(response.data);
        deactivateAuthenticatorData.value.otp = '';
        BpToast.show({
          color: 'green',
          title: $gettext('Authenticator delete success'),
          content: $gettext('Authenticator deletion successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Authenticator delete failed'),
          content: $gettext('Authenticator deletion failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// BACKUP CODES
    ///-------------------------------------------------------------------

    const backupCodes = ref<string[]>([]);
    const backupCodesWindow = ref(false);

    async function generateBackupCodes() {
      const response = await userStore.createBackupCodes(sessionUser.value.email);
      if (response && response.success && response.data) {
        user.value = clone(response.data);
        backupCodes.value = response.backupCodes;
        backupCodesWindow.value = true;
        BpToast.show({
          color: 'green',
          title: $gettext('Creating backup codes success'),
          content: $gettext('Backup code creation successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Creating backup codes failed'),
          content: $gettext('Backup code creation failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// CONTACT INFORMATION
    ///-------------------------------------------------------------------

    async function saveContactInformation() {
      const response = await userStore.update(user.value);
      if (response && response.success && response.data) {
        user.value = clone(response.data);
        BpToast.show({
          color: 'green',
          title: $gettext('Saving contact information success'),
          content: $gettext('Saving contact information was successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Saving contact information failed'),
          content: $gettext('Saving contact information has failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    async function resetContactInformation() {
      const storeUser = await userStore.getById(user.value._id || '');
      if (storeUser) {
        user.value = clone(storeUser);
        BpToast.show({
          color: 'green',
          title: $gettext('Reseting contact information success'),
          content: $gettext('Reseting contact information was successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Reseting contact information failed'),
          content: $gettext('Reseting contact information has failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// CUSTOMER INFORMATION
    ///-------------------------------------------------------------------

    async function saveCustomerInformation() {
      const response = await customerStore.updateCurrent(customer.value, sessionUser.value.email);
      if (response && response.success && response.data) {
        customer.value = clone(response.data);
        BpToast.show({
          color: 'green',
          title: $gettext('Saving customer information success'),
          content: $gettext('Saving customer information was successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Saving customer information failed'),
          content: $gettext('Saving customer information has failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    async function resetCustomerInformation() {
      const storeCustomer = await customerStore.getById(customer.value._id || '');
      if (storeCustomer) {
        customer.value = clone(storeCustomer);
        BpToast.show({
          color: 'green',
          title: $gettext('Reseting customer information success'),
          content: $gettext('Reseting customer information was successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Reseting customer information failed'),
          content: $gettext('Reseting customer information has failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// EMAIL NOTIFICATIONS
    ///-------------------------------------------------------------------

    function updateEmailNotificationProducts(type: 'download' | 'video', productUuid: Product['uuid']) {
      if (!user.value.email_notifications) {
        return;
      }
      const subscriptions = type === 'download' ? user.value.email_notifications.download : user.value.email_notifications.video
      if (subscriptions.products.includes(productUuid)) {
        const index = subscriptions.products.indexOf(productUuid);
        subscriptions.products.splice(index, 1);
      } else {
        subscriptions.products.push(productUuid);
      }
    }

    async function saveEmailNotifications() {
      const currentUser = await userStore.readByEmail(sessionUser.value.email);
      if(!user.value.email_notifications || !currentUser || !currentUser.data) {
        return;
      }
      const response = await userStore.update(user.value);
      if (response && response.success && response.data) {
        user.value = response.data;
        BpToast.show({
          color: 'green',
          title: $gettext('Update of email notification settings successfull'),
          content: $gettext('Update of email notification settings was successfull.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Update of email notification settings failed'),
          content: $gettext('Update of email notification settings has failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    async function resetEmailNotifications() {
      const response = await userStore.readByEmail(sessionUser.value.email);
      if (!response || !response.success || !response.data || !user.value.email_notifications) {
        BpToast.show({
          color: 'red',
          title: $gettext('Reset email notification settings failed'),
          content: $gettext('Reset email notification settings has failed.'),
          icon: 'triangle-exclamation',
        });
        return;
      }
      user.value = {
        ...user.value,
        email_notifications: response.data.email_notifications
      }
      BpToast.show({
        color: 'green',
        title: $gettext('Reset email notification settings successfull'),
        content: $gettext('Reset email notification settings was successfull.'),
        icon: 'circle-check',
      });
    }

    ///-------------------------------------------------------------------
    /// DELETE ACCOUNT
    ///-------------------------------------------------------------------

    const deleteAccountData = ref<{ password: string, otp: { inputEl: typeof BpInputVue | null, value: string, window: boolean }, window: boolean }>({
      password: '',
      otp: {
        inputEl: null,
        value: '',
        window: false,
      },
      window: false,
    })
    const deleteAccountRef = ((el: typeof BpInputVue) => deleteAccountData.value.otp.inputEl = el) as unknown as VNodeRef;

    async function deleteAccount() {
      const response = await userStore.deleteCurrent(sessionUser.value.email, deleteAccountData.value.password, deleteAccountData.value.otp.value);
      if (response && response.success) {
        deleteAccountData.value.window = false;
        router.push({ name: 'login' });
        BpToast.show({
          color: 'green',
          title: $gettext('User account deletion success'),
          content: $gettext('User account deletion was successful.'),
          icon: 'circle-check',
        });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('User account deletion failed'),
          content: $gettext('User account deletion has failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    function resetDeleteAccount() {
      deleteAccountData.value = {
        ...deleteAccountData.value,
        password: '',
        otp: {
          inputEl: null,
          value: '',
          window: false,
        }
      }
    }

    function deleteAccountShowOTPWindow() {
      deleteAccountData.value.otp.window = true;
      nextTick(() => deleteAccountData.value.otp.inputEl?.$el.querySelector('input')?.focus());
    }

    return {
      backupCodes,
      backupCodesWindow,
      copyToClipboard,
      customer,
      customerStore,
      sessionUser,
      generateBackupCodes,
      qrUri,
      resetContactInformation,
      resetCustomerInformation,
      salutationStore,
      saveContactInformation,
      saveCustomerInformation,
      slashIcon,
      user,
      userStore,
      languageStore,
      versionStore,

      // AUTHENTICATOR
      activateAuthenticator,
      activateAuthenticatorData,
      activateAuthenticatorErrors,
      setActivateAuthenticatorError,

      deactivateAuthenticator,
      deactivateAuthenticatorData,
      deactivateAuthenticatorErrors,
      setDeactivateAuthenticatorError,

      // CHANGE EMAIL
      changeEmail,
      changeEmailData,
      changeEmailErrors,
      changeEmailReset,
      setChangeEmailError,

      // CHANGE PASSWORD
      changePassword,
      changePasswordData,
      changePasswordErrors,
      changePasswordReset,
      setChangePasswordError,

      // DELETE ACCOUNT
      deleteAccount,
      deleteAccountData,
      deleteAccountErrors,
      deleteAccountRef,
      deleteAccountShowOTPWindow,
      resetDeleteAccount,
      setDeleteAccountError,

      // EMAIL NOTIFICATIONS
      updateEmailNotificationProducts,
      saveEmailNotifications,
      resetEmailNotifications,
    }
  }
});
